(function() {
  'use strict';

  function applyFilter(filter_id) {
    var filters = $('#imports_index_table').data('type');

    if (filters.length > 0) {
      var value = filters.indexOf(filter_id) >= 0
      return value
    } else {
      return true
    }
  }

  function applyBankFilter(bank_account_id) {
    var account_ids = $('#imports_index_table').data('params');

    if (account_ids.length > 0) {
      return $('#imports_index_table').data('bank-account-id-' + bank_account_id);
    } else {
      return true
    }
  }

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if (isFeatureToggleActive('obw_invoices_import')) {
      openCsvImportConnection()
    }
    if ($('#imports_index_table').length === 0) { return }

    var filter_array = [
      { id: 'file_imports', name: 'File Imports', visible: applyFilter('file_imports') },
    ];

    if (isFeatureToggleActive('plaid_feeds')) {
      filter_array.push({ id: 'bank_feeds', name: 'Bank Feeds (old)', visible: applyFilter('bank_feeds') })
      filter_array.push({ id: 'plaid_feeds', name: 'Bank Feeds', visible: applyFilter('plaid_feeds') })
    } else {
      filter_array.push({ id: 'bank_feeds', name: 'Bank Feeds', visible: applyFilter('bank_feeds') })
    }

    if ($('#is_pandle').length > 0) {
      filter_array.push({ id: 'stripe_feeds', name: 'Stripe Feeds', visible: applyFilter('stripe_feeds') })
      filter_array.push({ id: 'paypal_feeds', name: 'PayPal Feeds', visible: applyFilter('paypal_feeds') })
    }

    var bank_account_array = $('#imports_index_table').data('bank-accounts');
    $.each(bank_account_array, function(_index, value) {
      filter_array.push({ id: value[0], name: value[1], visible: applyBankFilter(value[0]) })
    });

    var $table = initBootgridTable($('#imports_index_table'), undefined, false, filter_array);
    var bf_table_controller = new BankFeedsTableController($table)
    bf_table_controller.init();

    document.addEventListener('turbo:click', function(){
      bf_table_controller.destroy();
    }, { once: true })
  }));
})();
